var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AView',{attrs:{"value":_vm.value,"name":_vm.service.sections[_vm.index] ? _vm.service.sections[_vm.index].freeSection.name : '',"actions":_vm.actions,"active":_vm.service.id === _vm.activeServiceId,"payload":{
    service: _vm.service,
    section: _vm.service.sections[_vm.index],
    freeSection: _vm.service.sections[_vm.index]
      ? _vm.service.sections[_vm.index].freeSection
      : undefined,
    viewToRemove: _vm.value,
  }},on:{"onHover":_vm.onHover,"onActivate":(e) => _vm.$emit('onActivate', e),"onMaximize":(e) => _vm.$emit('onMaximize', e),"onClose":(e) => _vm.$emit('onClose', e),"onExpand":(e) => _vm.$emit('onExpand', e),"onCollapse":(e) => _vm.$emit('onCollapse', e)},scopedSlots:_vm._u([{key:"content",fn:function({}){return [(_vm.service.sections[_vm.index])?_c('ServiceSectionFormFreeSectionVue',{ref:"form",staticClass:"pt-4",on:{"onFreeSectionStepCreate":_vm.onFreeSectionStepCreate,"onFreeSectionStepRemove":_vm.onFreeSectionStepRemove},model:{value:(_vm.service.sections[_vm.index].freeSection),callback:function ($$v) {_vm.$set(_vm.service.sections[_vm.index], "freeSection", $$v)},expression:"service.sections[index].freeSection"}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }