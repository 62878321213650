<template>
  <AView
    :value="value"
    :name="
      service.sections[index] ? service.sections[index].freeSection.name : ''
    "
    :actions="actions"
    :active="service.id === activeServiceId"
    :payload="{
      service,
      section: service.sections[index],
      freeSection: service.sections[index]
        ? service.sections[index].freeSection
        : undefined,
      viewToRemove: value,
    }"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
  >
    <template v-slot:content="{}">
      <ServiceSectionFormFreeSectionVue
        ref="form"
        class="pt-4"
        v-if="service.sections[index]"
        v-model="service.sections[index].freeSection"
        @onFreeSectionStepCreate="onFreeSectionStepCreate"
        @onFreeSectionStepRemove="onFreeSectionStepRemove"
      ></ServiceSectionFormFreeSectionVue>
    </template>
  </AView>
</template>
    
    <script>
import { mapGetters, mapState } from "vuex";
import { PermissionsHelper } from "../../../../../../../components/helpers/permissions.helper";
import AView from "../../../../../../../components/wad/organisms/layout/A-View.vue";
import ServiceSectionFormFreeSectionVue from "../../../../../../../components/wad/organisms/services/editorForms/ServiceSectionFormFreeSection.vue";
import { SERVICE_BUTTONS } from "../../../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    ServiceSectionFormFreeSectionVue,
  },
  data() {
    return {
      service: {
        sections: [],
      },
    };
  },
  created() {
    this.$store.dispatch("ServiceStore/GetService", {
      id: this.value.relation.params.serviceId,
    });
    this.service = this.serviceById(this.value.relation.params.serviceId);
  },

  computed: {
    ...mapState("ServiceStore", ["activeServiceId", "displayedServices"]),
    ...mapGetters("ServiceStore", ["serviceById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        SERVICE_BUTTONS.FREE_SECTION_REMOVE,
        this.value.permissions
      );
    },
    index: function () {
      return this.service.sections && this.service.sections.length
        ? this.service.sections.findIndex(
            (section) => section.id === this.value.relation.params.sectionId
          )
        : undefined;
    },
  },

  methods: {
    onHover() {
      console.log("onHoveronHover");

      this.$store.commit(
        "ServiceStore/setActiveId",
        this.value.relation.params.serviceId
      );
    },
    // ==============FREE SECTION===============
    async onFreeSectionUpdate() {
      this.$store.dispatch("ServiceStore/onFreeSectionUpdate", {
        service: this.service,
        section: this.service.sections[this.index],
      });
    },

    async onFreeSectionStepCreate() {
      this.$store.dispatch("ServiceStore/onFreeSectionStepCreate", {
        service: this.service,
        freeSection: this.service.sections[this.index].freeSection,
      });
    },

    async onFreeSectionStepRemove(freeSection, step) {
      this.$store.dispatch("ServiceStore/onFreeSectionStepRemove", {
        service: this.service,
        freeSection,
        step,
      });
    },
  },
  watch: {
    displayedServices: {
      async handler(newVal) {
        console.log("displayedServices");

        if (newVal && !newVal.IN_PROGRESS) {
          this.service = this.serviceById(this.value.relation.params.serviceId);
        }
      },
      deep: true,
    },
    service: {
      async handler(newVal, oldVal) {
        console.log("service Something");
        if (!(oldVal && oldVal.id) || !this.$refs.form) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("ServiceStore/UpdateService", {
            service: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>