export const FREE_SECTIONS_TYPES = {
    TABS: {
        value: "TABS",
        name: "Tabs",
        description: "Steps will be presented as tabs, each step will be a separated tab with content"
    },
    SLIDER: {
        value: "SLIDER",
        name: "Slider",
        description: "Steps will be presented a a Text with parallax background. Background image will correspond to the service type. "
    },
    WITHOUT_STEPS: {
        value: "WITHOUT_STEPS",
        name: 'Without Steps',
        description: "Steps will be disabled and only main fields will be displayed."
    },
    ICONS: {
        value: "ICONS",
        name: "Icons",
        description: 'Steps will be presented together with icon selected. You can specify how to display them better.'
    }
}