<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :items="items"
    item-text="name"
    item-value="value"
    clearable
    cache-items
    :color="$attrs.dark || $attrs.dark === '' ? 'white' : 'accent'"

  >
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
            mdi-help
          </v-icon>
        </template>
        <span>{{ helpText }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>


<script>
import { FREE_SECTIONS_TYPES } from "../../../../constants/defaults/FreeSections";

export default {
  props: {
    value: { type: String },
  
    helpText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: Object.values(FREE_SECTIONS_TYPES),
    };
  },
};
</script>