<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col class="pa-0 ma-0">
        <v-tabs background-color="transparent" v-model="tab">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab :key="'general'"> General </v-tab>
          <v-tab
            :disabled="form.stepsType === freeSectionTypes.WITHOUT_STEPS.value"
            :key="'steps'"
          >
            Steps
          </v-tab>
        </v-tabs>
        <!-- <v-divider color="white" class="mt-2">

        </v-divider> -->
        <v-tabs-items class="transparent" dark v-model="tab">
          <v-tab-item :key="'general'">
            <v-row>
              <v-col class="free-section-col" cols="12" lg="6">
                <v-row>
                  <v-col class="free-section-col" cols="12" lg="6" md="3">
                    <HelpFormInputVue
                      dark
                      v-model="form.name"
                      label="Section Name"
                      required
                      :helpText="'This is a name of the new section'"
                    ></HelpFormInputVue>
                  </v-col>
                  <v-col class="free-section-col" cols="12" lg="6" md="3">
                    <HelpFormSwitchVue
                      dark
                      color="red"
                      :disabled="form.stepsType == 'tabs'"
                      v-model="form.steps_auto_spin"
                      label="Steps Autospin"
                    ></HelpFormSwitchVue>
                  </v-col>
                  <v-col class="free-section-col" cols="12" lg="6" md="3">
                    <SelectorFreeStepsTypesVue
                      dark
                      v-model="form.steps_type"
                      label="Steps type"
                      :helpText="'You can select different steps types. This option impacts visual aspect of the Section rendering.'"
                    ></SelectorFreeStepsTypesVue>
                  </v-col>
                  <v-col cols="12" lg="6" md="3"> </v-col>
                </v-row>
              </v-col>

              <v-col class="free-section-col" cols="12" lg="6" md="6">
                <HelpFormInputVue
                  dark
                  textarea
                  :rows="5"
                  v-model="form.content"
                  label="Main Content"
                  required
                ></HelpFormInputVue>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :key="'steps'">
            <v-row>
              <v-col cols="12">
                <FormSliderVue
                  v-model="form.steps"
                  :itemName="'header'"
                  :addText="'Add New Step'"
                  @onAdd="onAddStep"
                  @onRemove="onStepRemove"
                >
                  <template v-slot:form="{ item, attrs }">
                    <v-row v-bind="attrs">
                      <v-col class="free-section-col" cols="12" lg="6">
                        <SelectorIconsVue
                          dark
                          :helpText="'Please select icon that will be associated with current step'"
                          v-model="item.icon"
                        >
                        </SelectorIconsVue>
                        <HelpFormInputVue
                          dark
                          v-model="item.header"
                          label="Header"
                          required
                        ></HelpFormInputVue>
                        <HelpFormInputVue
                          dark
                          v-model="item.sub_header"
                          label="Sub Header"
                          required
                        ></HelpFormInputVue>
                      </v-col>
                      <v-col class="free-section-col" cols="12" lg="6">
                        <HelpFormInputVue
                          dark
                          textarea
                          :rows="5"
                          v-model="item.content"
                          label="Step Main Content"
                          required
                        ></HelpFormInputVue>
                      </v-col>
                    </v-row>
                  </template>
                </FormSliderVue>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
import { FREE_SECTIONS_TYPES } from "../../../../constants/defaults/FreeSections";
import SelectorFreeStepsTypesVue from "../../../atoms/common/autocompletes/SelectorFreeStepsTypes.vue";
import SelectorIconsVue from "../../../atoms/common/autocompletes/SelectorIcons.vue";
import FormSliderVue from "../../../atoms/common/FormSlider.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";
import HelpFormSwitchVue from "../../../atoms/common/inputs/HelpFormSwitch.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: true,
      tab: "general",
      form: this.value,

      // common
      freeSectionTypes: FREE_SECTIONS_TYPES,

      // ======rules
      nameRules: [(v) => !!v || "Section Name is required"],

      serviceLevelRules: [(v) => !!v || "Service Level is required"],
      serviceNameRules: [(v) => !!v || "Service Name is required"],
      serviceCodeRules: [(v) => !!v || "Service Name is required"],
      serviceBtnRules: [(v) => !!v || "Action Button Text is required"],
      serviceDescriptionRules: [(v) => !!v || "Description is required"],
      select: null,
      stepsTypes: ["tabs", "slider", "without_steps"],
      checkbox: false,
    };
  },
  components: {
    HelpFormInputVue,
    HelpFormSwitchVue,
    FormSliderVue,
    SelectorFreeStepsTypesVue,
    SelectorIconsVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onAddStep() {
      this.$emit("onFreeSectionStepCreate", this.form);
    },
    onStepRemove(step) {
      this.$emit("onFreeSectionStepRemove", this.form, step);
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    value: {
      handler(newVal) {
        console.log('On Value Changed Something went wrong...')
        this.form = newVal;
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
.free-section-col{
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>